// components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'; // Certifique-se de que o CSS está sendo importado

const Navbar = ({ nomeUsuario, paginaAtual }) => {
    return (
        <nav className="navbar">
            <div className="navbar-content">
                <span className="navbar-title">{paginaAtual}</span>
                <div className="navbar-user">
                    <span>Bem-vindo, {nomeUsuario}</span>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
