import "./busca.css";

function Busca({ criterioBusca, handleCriterioChange, termoBusca, handleBuscaChange, onBuscar, texto }) {
    return (
        <div className="input-group">
            <select value={criterioBusca} onChange={handleCriterioChange} className="form-select">
                <option value="descricao">Nome do Cliente</option>
                <option value="idpedido">Código</option>
                <option value="vendedor">Nome do Vendedor</option>
                <option value="valor">Valor Total da Venda</option>
            </select>
            <input 
                type="text" 
                className="form-control busca" 
                placeholder={texto} 
                value={termoBusca}
                onChange={handleBuscaChange}
            />
            <button className="btn btn-primary" type="button" onClick={onBuscar}>Buscar Venda</button>
        </div>
    );
}

export default Busca;
