import { useEffect, useState } from "react";
import { Dock } from "react-dock";
import "./ajustarestoque-cad.css";
import api from "../../services/api.js";

function AjustaestoqueCad(props){

    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [etapas, setEtapas] = useState([]);
    const [idUsuario, setIdUsuario] = useState(0);

    const [operadora, setOperadora] = useState("");
    const [codigo, setCodigo] = useState("0");    
    const [nsserial, setNsserial] = useState("");    
    const [serial, setSerial] = useState("");

    // Função para obter o ID do usuário logado
    const getLoggedUserId = () => {
        // Exemplo de como pegar o ID do usuário logado (assumindo que o ID está salvo como um número simples ou string no localStorage)
        const user = localStorage.getItem('idusuario');  // Suponha que o ID do usuário esteja salvo no localStorage
        //console.log("Valor do localStorage (idusuario):", user);
    
        return user ? parseInt(user) : 0;  // Se for um número, converte para inteiro; caso contrário, retorna 0
    };

    
    function SalvarDados() {
        // Aqui, obtemos diretamente o ID do usuário logado
        const userId = getLoggedUserId();  // Obtenha o ID do usuário logado
        //console.log("ID do Usuário na hora de salvar:", userId); // Verifique se está correto
        
        const params = {
            operadora,
            codigo,
            nsserial,
            serial,
            idUsuario: userId  // Aqui estamos usando diretamente o userId
        };
    
        if (operacao === "new") {
            api.post("/maquininhas", params)
            .then((resp) => {
                if (resp.status === 200) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                setEtapas([]);
                alert("Erro ao inserir dados");
            });
        } else {
            api.put("/maquininhas/" + codigo, params)
            .then((resp) => {
                if (resp.status === 200) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                setEtapas([]);
                alert("Erro ao editar dados");
            });
        }
    }

    
    useEffect(() => {
    
        const userId = getLoggedUserId();
        //console.log("User ID obtido:", userId); // Verifique o ID obtido
        setIdUsuario(userId);  // Atualiza o estado com o ID do usuário logado
        //console.log("Estado idUsuario após setIdUsuario:", idUsuario); // Isso pode ainda exibir o valor antigo, por causa da assincronicidade
         
        window.addEventListener('openSidebar', (param) => {
            if (param.detail.operacao === "new") {
                setCodigo(0);
                setIdUsuario(userId);  // Garante que o idUsuario atualizado seja aplicado
                setOperadora("");
                setNsserial("");
                setSerial("");
            } else {
                api.get("/maquininhas/" + param.detail.codigo)
                .then((resp) => {
                    setIdUsuario(resp.data.idusuario);  // Garante que o idUsuario correto seja aplicado
                    setCodigo(resp.data.id);
                    setOperadora(resp.data.operadora);
                    setNsserial(resp.data.nsserial);
                    setSerial(resp.data.serial);
                })
                .catch((err) => {                    
                    alert("Erro ao carregar dados");
                    console.log(err);
                });
            }

            setShow(true);
            setOperacao(param.detail.operacao);
        });
    }, []);

    return <Dock position="right"
                 isVisible={show}
                 fluid={false}
                 size={410}   
                 onVisibleChange={(visible) => {
                    setShow(visible);
                 }}         
                 >

        <div className="container-fluid h-100 pt-4 sidebar p-4 ">

            <h4 className="d-inline">
                {operacao === "edit" ? "Editar Maquininha" : "Nova Maquininha"}
            </h4>

            <div className="row tela-cad">

                <div className="col-12 mt-3">
                    <small className="text-secondary">Código da Maquininha</small>
                    <input type="text" className="form-control" onChange={(e) => setCodigo(e.target.value)} value={codigo} />
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Operadora</small>
                    <input type="text" className="form-control" onChange={(e) => setOperadora(e.target.value)} value={operadora}/>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Nº Serial</small>
                    <input type="text" className="form-control" onChange={(e) => setNsserial(e.target.value)} value={nsserial}/>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Serial</small>
                    <input type="text" className="form-control" onChange={(e) => setSerial(e.target.value)} value={serial}/>
                </div>

            </div>

            <div className="row align-items-end footer">
                <div className="text-center">
                    <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Cancelar</button>
                    <button className="btn btn-primary" onClick={SalvarDados}>Salvar Dados</button>
                </div> 
            </div>

        </div>

    </Dock>
}

export default AjustaestoqueCad;
