import "./login.css";
import logo from "../../assets/logo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api.js";

function Login(){

    const navigate = useNavigate();
    const [login, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [erro, setErro] = useState("");
    const [loading, setLoading] = useState(false);

    function ProcessaLogin(){

        setLoading(true);

        const params = {
            login,
            senha
        };

        api.post("/usuarios/login", params)
        .then((resp) => {
            setLoading(false);

            if (resp.status == 200)
            {
                localStorage.setItem("idusuario", resp.data.id);
                localStorage.setItem("nome_usuario", resp.data.nome);
                navigate("/dashboard");
            } else {
                setErro("Erro ao realizar login");
            }

        })
        .catch((err) => {
            setLoading(false);

            if (err.response?.data.erro) {
                setErro(err.response.data.erro);
            } else {
                setErro("Erro ao realizar login");
            }
        })
    }

    return <div className="d-flex justify-content-center align-items-center content">
        <form className="form-signin">
            <div className="text-center">
                    <img className="mb-5" src={logo} alt=""  width="230" heigth="230"/>
            </div>
            <h3 className="mb-3">Login</h3>
           
            <div className="form-floating">
                <input type="login" className="form-control" id="floatingInput" style={{ textTransform: 'uppercase' }} onChange={(e) => setEmail(e.target.value.toUpperCase())} />
                <label for="floatingInput">Usuário</label>
            </div>
            <div className="form-floating">
                <input type="password" className="form-control" 
                        id="floatingPassword" onChange={(e) => setSenha(e.target.value)} />
                <label for="floatingPassword">Senha</label>
            </div>

            {
                loading ? 
                    <span className="btn btn-primary w-100 py-2 disabled">
                        <div class="spinner-border spinner-border-sm text-light me-2" role="status"></div>
                        Acessando...
                    </span>
                    
                : <button onClick={ProcessaLogin} className="btn btn-primary w-100 py-2" type="button">Acessar</button>
            }
            

            {
                erro.length > 0 ?
                <div class="alert alert-danger mt-4 text-center" role="alert">{erro}</div>                        
                : null
            }

            <div className="text-center">
                <p className="mt-5 mb-3 text-secondary">Cognitiva Gestor Pedido WEB</p>
            </div>    
        </form>
    </div>
}

export default Login;