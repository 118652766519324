// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* navbar.css */

.navbar {
    background-color: #007bff; /* Cor de fundo (azul) */
    padding: 10px 20px; /* Adiciona espaçamento interno */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
    color: white; /* Cor do texto branco */
}

.navbar-content {
    display: flex;
    justify-content: space-between; /* Espaça os itens entre esquerda e direita */
    align-items: center; /* Centraliza verticalmente os itens */
    flex-wrap: wrap; /* Para garantir que os itens não ultrapassem a tela */
}

.navbar-title {
    font-size: 1.8rem; /* Tamanho da fonte do título */
    font-weight: bold; /* Negrito no título */
    margin-right: 20px; /* Adiciona espaçamento à direita do título */
}

.navbar-user {
    display: flex;
    align-items: center; /* Centraliza verticalmente */
}

.navbar-user span {
    margin-right: 15px; /* Espaçamento entre o texto de boas-vindas e o link de logout */
}

.logout-link {
    text-decoration: none; /* Remove o sublinhado do link */
    color: white; /* Cor do link */
    font-weight: bold; /* Deixa o link em negrito */
}

.logout-link:hover {
    text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,yBAAyB,EAAE,wBAAwB;IACnD,kBAAkB,EAAE,iCAAiC;IACrD,wCAAwC,EAAE,WAAW;IACrD,YAAY,EAAE,wBAAwB;AAC1C;;AAEA;IACI,aAAa;IACb,8BAA8B,EAAE,6CAA6C;IAC7E,mBAAmB,EAAE,sCAAsC;IAC3D,eAAe,EAAE,sDAAsD;AAC3E;;AAEA;IACI,iBAAiB,EAAE,+BAA+B;IAClD,iBAAiB,EAAE,sBAAsB;IACzC,kBAAkB,EAAE,6CAA6C;AACrE;;AAEA;IACI,aAAa;IACb,mBAAmB,EAAE,6BAA6B;AACtD;;AAEA;IACI,kBAAkB,EAAE,gEAAgE;AACxF;;AAEA;IACI,qBAAqB,EAAE,gCAAgC;IACvD,YAAY,EAAE,gBAAgB;IAC9B,iBAAiB,EAAE,4BAA4B;AACnD;;AAEA;IACI,0BAA0B,EAAE,0CAA0C;AAC1E","sourcesContent":["/* navbar.css */\n\n.navbar {\n    background-color: #007bff; /* Cor de fundo (azul) */\n    padding: 10px 20px; /* Adiciona espaçamento interno */\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */\n    color: white; /* Cor do texto branco */\n}\n\n.navbar-content {\n    display: flex;\n    justify-content: space-between; /* Espaça os itens entre esquerda e direita */\n    align-items: center; /* Centraliza verticalmente os itens */\n    flex-wrap: wrap; /* Para garantir que os itens não ultrapassem a tela */\n}\n\n.navbar-title {\n    font-size: 1.8rem; /* Tamanho da fonte do título */\n    font-weight: bold; /* Negrito no título */\n    margin-right: 20px; /* Adiciona espaçamento à direita do título */\n}\n\n.navbar-user {\n    display: flex;\n    align-items: center; /* Centraliza verticalmente */\n}\n\n.navbar-user span {\n    margin-right: 15px; /* Espaçamento entre o texto de boas-vindas e o link de logout */\n}\n\n.logout-link {\n    text-decoration: none; /* Remove o sublinhado do link */\n    color: white; /* Cor do link */\n    font-weight: bold; /* Deixa o link em negrito */\n}\n\n.logout-link:hover {\n    text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
