import { useEffect, useState } from "react";
import { Dock } from "react-dock";
import "./entregador-cad.css";
import api from "../../services/api.js";

function EntregadorCad(props){

    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [grupos, setGrupos] = useState([]);
    const [idUsuario, setIdUsuario] = useState(0);  // ID do usuário logado

    const [codigo, setIdProduto] = useState(0);
    const [tipo, setTipo] = useState("");
    const [grupo, setGrupo] = useState([""]);
    const [situacao, setSituacao] = useState("");
    const [codbarra, setCodBarra] = useState("");
    const [descricao, setDescricao] = useState("");    
    const [grupo_nome, setGrupoNome] = useState("");
    const [precocompra, setPrecoCompra] = useState(0);
    const [precocusto, setPrecoCusto] = useState(0);
    const [pr_venda, setPrVenda] = useState(0);
    const [qtdatacado, setQtdAtacado] = useState(2);
    const [preco_atacado, setPrecoAtacado] = useState(0);
    const [qtd_atual, setQtd_Atual] = useState("");

    // Função para obter o ID do usuário logado
    const getLoggedUserId = () => {
        // Exemplo de como pegar o ID do usuário logado (pode vir do localStorage, API, ou contexto)
        const user = localStorage.getItem('idUsuario');  // Suponha que o ID do usuário esteja salvo no localStorage
        return user ? user.id : 0;
    };

    function ComboGrupos() {
        api.get("/maquininhas/listagem")
        .then((resp) => {
            const data = resp.data;
            if (Array.isArray(data)) {
                setGrupos(data);
            } else {
                setGrupos([data]);
            }
        })
        .catch((err) => {
            setGrupos([]); 
            alert("Erro ao montar combo");
        });
    }

    function SalvarDados(){
        const params = {
            idUsuario,  // Envia o ID do usuário logado
            grupo,
            descricao
        };

        if (operacao === "new") {
            api.post("/entregadores", params)
            .then((resp) => {
                if (resp.status === 201) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                alert("Erro ao inserir dados");
            });

        } else {
            api.put("/entregadores/" + codigo, params)
            .then((resp) => {
                if (resp.status === 200) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                setGrupos([]);
                alert("Erro ao editar dados");
            });
        }
    }

    useEffect(() => {
        ComboGrupos();
        
        // Obter o ID do usuário logado e atualizar o estado
        const userId = getLoggedUserId();
        setIdUsuario(userId);  // Atualiza o estado com o ID do usuário logado
        
        const handleSidebarEvent = (param) => {
            if (param.detail.operacao === "new") {
                setIdProduto("");
                setDescricao("");
            } else {
                api.get("/entregadores/" + param.detail.codigo)
                .then((resp) => {
                    setIdUsuario(resp.data.idusuario);
                    setIdProduto(resp.data.id);
                    setGrupo(resp.data.id_maquininha);
                    setDescricao(resp.data.nome);
                })
                .catch((err) => {                    
                    alert("Erro ao carregar dados");
                    console.log(err);
                });
            }

            setShow(true);
            setOperacao(param.detail.operacao);
        };

        window.addEventListener('openSidebar', handleSidebarEvent);

        return () => {
            window.removeEventListener('openSidebar', handleSidebarEvent);
        };
    }, []);  // O useEffect só executa uma vez no início


    return (
        <Dock position="right"
             isVisible={show}
             fluid={false}
             size={410}   
             onVisibleChange={(visible) => {
                setShow(visible);
             }}         
        >
            <div className="container-fluid h-100 pt-4 sidebar p-4 ">
                <h4 className="d-inline">
                    {operacao === "edit" ? "Editar Entregador" : "Novo Entregador"}
                </h4>

                <div className="row tela-cad">
                    <div className="col-12 mt-4">
                        <small className="text-secondary">Maquininha</small>
                        <div className="form-control mb-2">
                            <select name="id_maquininha" id="id_maquininha" onChange={(e) => setGrupo(e.target.value)} value={grupo}>
                                <option value="">Selecione a Maquininha</option>
                                {Array.isArray(grupos) && grupos.length > 0 ? (
                                    grupos.map((item) => (
                                        <option key={item.id} value={item.id}>{item.operadora}</option>
                                    ))
                                ) : (
                                    <option value="">Nenhuma maquininha disponível</option>
                                )}
                            </select>
                        </div>
                    </div>            
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Códigos</small>
                        <input type="text" className="form-control" onChange={(e) => setIdProduto(e.target.value)} value={codigo} disabled />
                    </div>
                    
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Nome do Entregador</small>
                        <input type="text" className="form-control" onChange={(e) => setDescricao(e.target.value)} value={descricao} />
                    </div>
                </div>

                <div className="row align-items-end footer">
                    <div className="text-center">
                        <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Cancelar</button>
                        <button className="btn btn-primary" onClick={SalvarDados}>Salvar Dados</button>
                    </div> 
                </div>
            </div>
        </Dock>
    );
}

export default EntregadorCad;