import React, { useEffect, useState, useCallback, useMemo } from "react";
import Menu from "../../components/menu/menu.jsx";
import Busca from "../../components/busca/busca.jsx";
import Indicador from "../../components/indicador/indicador.jsx";
import Grafico from "../../components/grafico/grafico.jsx";
import Navbar from "../../components/navbar/navbar.jsx";
import api from "../../services/api.js";
import DataTable from "react-data-table-component";

function Dashboard() {
  const [dadosIndicadores, setDadosIndicadores] = useState({
    pedidos_abertos: 0,
    pedidos_cancelados: 0,
    pedidos_fechados: 0,
    pedidos_atendidos_dia: 0,
  });

  const [dadosPedidosEntregador, setDadosPedidosEntregador] = useState([["Entregador", "Total de Pedidos"], [0, 0]]);
  const [nomeUsuario, setNomeUsuario] = useState("Usuário");
  const [idUsuario, setIdUsuario] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [dados, setDados] = useState([]);  
  const [loading, setLoading] = useState(false); // Estado de carregamento
  // Definição das colunas da tabela
  const columns = useMemo(() => [
    { name: 'Nome do Entregador', selector: row => row.entregador, sortable: true, compact: true, left: true },
    { name: 'Total de Pedidos', selector: row => row.total_pedidos, sortable: true, compact: true, center: true },
    { 
        name: 'Valor Total de Pedidos', 
        selector: row => row.Valor_total, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.Valor_total)
    },    
    { name: 'Total a Receber', selector: row => row.total_em_aberto, sortable: true, compact: true, center: true },
    { 
        name: 'Valor a Receber', 
        selector: row => row.Valor_total_a_receber, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.Valor_total_a_receber)
    },    
    { name: 'Total Entregues', selector: row => row.total_fechados, sortable: true, compact: true, center: true },
    { 
        name: 'Valor Entregues', 
        selector: row => row.Valor_total_recebidos, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.Valor_total_recebidos)
    },    
    { name: 'Total Cancelados', selector: row => row.total_cancelados, sortable: true, compact: true, center: true },
    { 
        name: 'Valor Cancelados', 
        selector: row => row.Valor_total_cancelados, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.Valor_total_cancelados)
    },    
  ], []);
        
    function MontarDashboard() {
      Promise.all([
        api.get("/dashboard/resumos"),
        api.get("/dashboard/pedidos-entregador")
      ])
        .then(([resumosResp, entregadorResp]) => {
          setDadosIndicadores(resumosResp.data);
          setDados(entregadorResp.data.dados);
        //   // Transformar dados para o formato do gráfico
        //   const dadosGrafico = [["Entregador", "Total de Pedidos", "Em Aberto", "Fechados", "Cancelados"]];
        //   entregadorResp.data.forEach((row) => {
        //       console.log(row);
        //       dadosGrafico.push([
        //         row.entregador,
        //         row.total_fechados + row.total_em_aberto + row.total_cancelados, // Total de pedidos
        //         row.total_em_aberto,
        //         row.total_fechados,
        //         row.total_cancelados
        //       ]);
        //     });
    
        //   // Verifique se há pelo menos 2 colunas e pelo menos 1 linha de dados
        //   if (dadosGrafico.length > 1 && dadosGrafico[1].length >= 2) {
        //     setDadosPedidosEntregador(dadosGrafico);
        //   } else {
        //     console.error("Dados do gráfico estão no formato inválido:", dadosGrafico);
        //     setDadosPedidosEntregador([["Entregador", "Total de Pedidos"]]); // fallback em caso de erro
        //   }
        })
        .catch((err) => {
          console.log("Erro ao carregar dados:", err);
        });
    }


  useEffect(() => {
    const usuario = localStorage.getItem("nome_usuario");
    const setIdUsuario = localStorage.getItem("idusuario");
    setNomeUsuario(usuario || "Usuário");
    MontarDashboard();
  }, []);

  return (
    <>
      <Navbar nomeUsuario={nomeUsuario} paginaAtual="Dashboard" />
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="dashboard" />
          </div>

          <div className="col py-3 me-3">
            <div className="d-flex justify-content-between">
              <h1>Dashboard</h1>
              <button onClick={MontarDashboard} className="btn btn-primary ms-4">Atualizar</button>
            </div>

            <div className="row">
              <div className="col-md-3 mt-4">
                <Indicador
                  titulo="Pedidos Abertos"
                  valor={dadosIndicadores.pedidos_abertos}
                  rodape="Pedidos"
                />
              </div>
              <div className="col-md-3 mt-4">
                <Indicador
                  titulo="Pedidos Cancelados"
                  valor={dadosIndicadores.pedidos_cancelados}
                  rodape="Pedidos"
                />
              </div>
              <div className="col-md-3 mt-4">
                <Indicador
                  titulo="Pedidos Fechados"
                  valor={dadosIndicadores.pedidos_fechados}
                  rodape="Pedidos"
                />
              </div>
              <div className="col-md-3 mt-4">
                <Indicador
                  titulo="Pedidos Atendidos Hoje"
                  valor={dadosIndicadores.pedidos_atendidos_dia}
                  rodape="Pedidos"
                />
              </div>

            </div>
            <div className="row">
            </div>
            <div className="row">
                <div className="bg-white p-4 rounded-4 border">
                  <div className="d-flex justify-content-between mb-3">
                    <h2>Entregadores</h2>
                  </div>
                  {isMobile ? (
                    <div className="card-container">
                      {dados.map((item) => (
                        <div className="card" key={item.id}>
                          <div className="card-header">{item.nome}</div>
                          <div className="card-body">
                            <p><strong>Código:</strong> {item.id}</p>
                            <p><strong>Entregador:</strong> {item.nome}</p>
                            <p><strong>maquininha:</strong> {item.maquininha}</p>
                            <p><strong>Situação:</strong> {item.ativo}</p>
                            <button onClick={() => Editar(item.id)} className="btn btn-primary ms-3">
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            <button onClick={() => Excluir(item.id)} className="btn btn-danger ms-3">
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="data-table-container">
                      <DataTable
                        columns={columns}
                        data={dados}
                        //pagination={true}
                        //paginationComponentOptions={paginationOptions}
                        noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                        //paginationServer={true}
                        //paginationTotalRows={total_registros}
                        //onChangeRowsPerPage={ChangePerRows}
                        //onChangePage={ChangePage}
                        progressPending={loading}
                      />
                    </div>
                  )}
                </div>            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
