import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Entregador from "./pages/entregador/entregador.jsx"
import Maquininhas from "./pages/ajustarestoque/ajustarestoque.jsx"
import Pedidos from "./pages/vendas/vendas.jsx"
import Registro from "./pages/registro/registro.jsx";
import Login from "./pages/login/login.jsx";
import PrivateRoute from "./components/private-route/private-route.jsx";

function Rotas(){
    return <BrowserRouter>
        <Routes>
            <Route path="/registro" element={<Registro />} />
            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />

            <Route path="/" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />

            <Route path="/entregador" element={
                <PrivateRoute>
                    <Entregador />
                </PrivateRoute>
            } />
            
            <Route path="/maquininhas" element={
                <PrivateRoute>
                    <Maquininhas />
                </PrivateRoute>
            } />
            
            <Route path="/pedidos" element={
                <PrivateRoute>
                    <Pedidos />
                </PrivateRoute>
            } />            
        </Routes>
    </BrowserRouter>
}

export default Rotas;