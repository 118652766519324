import React, { useEffect, useState, useCallback, useMemo } from "react";
import Busca from "../../components/busca/buscavenda.jsx";
import Menu from "../../components/menu/menu.jsx";
import Indicador from "../../components/indicador/indicadorvenda.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import VendaCad from "../venda-cad/venda-cad.jsx";
import Navbar from "../../components/navbar/navbar.jsx"; // Importando o componente Navbar
import api from "../../services/api.js";

function Pedidos() {
    
  const [termoBusca, setTermoBusca] = useState(""); // Termo da busca
  const [criterioBusca, setCriterioBusca] = useState("descricao"); // Critério da busca  
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [isMobile, setIsMobile] = useState(false);
  const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
  const [dados, setDados] = useState([]);
  const [total_registros, setTotalRegistros] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [nomeUsuario, setNomeUsuario] = useState("Usuário"); // Estado para o nome do usuário
  
    const columns = useMemo(() => [
      { name: 'Nº Pedido', selector: row => row.idpedido, sortable: true, width: "120px" },
      {
        name: 'Data Emissão',
        selector: row => new Date(row.dta_pedido).toLocaleDateString('pt-BR', { timeZone: 'UTC' }), // Formata a data no padrão DD/MM/YYYY
        sortable: true
      },      
      { name: 'Cliente', selector: row => row.descricao, sortable: true, compact: true },
      { name: 'Vendedor', selector: row => row.nome_entregador, sortable: true },
      { name: 'Maquininha', selector: row => row.nome_maquininha, sortable: true },
      { name: 'Situação', selector: row => row.nome_situacao, sortable: true },
      { 
        name: 'Valor Pedido', 
        selector: row => row.valor, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor)
      },
        {
          cell: (row) => (
          row.nome_situacao === 'ABERTO' ? ( // Verifica se a situação é "Aberta"
            <>
              <button onClick={() => Excluir(row.id)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button>
            </>
            ): null
          )}      
      /*{
        cell: (row) => (
          row.SITUACAO === 'Aberta' ? ( // Verifica se a situação é "Aberta"
            <>
              <button onClick={() => Editar(row.CODIGO)} className="btn btn-primary ms-3">
                <i className="bi bi-pencil-square"></i> {/* Ícone de edição *//*}
              </button>
              <button onClick={() => Excluir(row.CODIGO)} className="btn btn-danger ms-3">
                <i className="bi bi-trash3-fill"></i> {/* Ícone de exclusão *//*}
              </button>
            </>
          ) : null // Se não for "Aberta", não renderiza nada
        ),
        width: "200px",
        right: true
      }*/
    ], []);

  const paginationOptions = {        
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos'        
  };

  const ListarVendas = useCallback(async (pagina = 1, qtd_reg_pagina = 10, termoBusca = "", criterioBusca = "descricao") => {
    setLoading(true);
    try {
      const resp = await api.get("/pedidos", { params: { pagina, qtd_reg_pagina, busca: termoBusca, criterio: criterioBusca } });
      setTotalRegistros(resp.data.total_registros);
      setDados(resp.data.dados);
    } catch (err) {
      setTotalRegistros(0);
      setDados([]);
      alert("Erro ao carregar Vendas");
    } finally {
      setLoading(false);
    }
  }, []);

  const Excluir = useCallback((id) => {
    confirmAlert({
      title: 'CANCELAMENTO',
      message: 'Confirma Cancelamento do Pedido?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              await api.delete("/pedidos/" + id);
              ListarVendas(currentPage, qtd_reg_pagina);
            } catch (err) {
              alert("Erro ao cancelar o Venda");
            }
          }
        },
        { label: 'Não' }
      ]
    });
  }, [currentPage, qtd_reg_pagina, ListarVendas]);

  const Editar = (id) => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "edit", codigo: id } });
    window.dispatchEvent(event);
  };

  const Novo = () => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "new" } });
    window.dispatchEvent(event);
  };

  const ChangePerRows = (newQtd, page, termoBusca, criterioBusca) => {
    setQtdRegPagina(newQtd);
    setCurrentPage(page);
    ListarVendas(page, newQtd, termoBusca, criterioBusca);
  };

  const ChangePage = (page) => {
    setCurrentPage(page);
    ListarVendas(page, qtd_reg_pagina, termoBusca, criterioBusca);
  };

  const RefreshDados = () => ListarVendas(1, qtd_reg_pagina, termoBusca, criterioBusca);
    function handleBuscaChange(e) {
        setTermoBusca(e.target.value); // Atualiza o termo de busca
    }

    function handleCriterioChange(e) {
        setCriterioBusca(e.target.value); // Atualiza o critério de busca
    }

    function handleBuscar() {
        ListarVendas(1, qtd_reg_pagina, termoBusca, criterioBusca); // Executa a busca com termo e critério
    }

  // Função para detectar ambiente (desktop ou mobile)
  useEffect(() => {
    // Supondo que o nome do usuário seja armazenado no localStorage
    const usuario = localStorage.getItem("nome_usuario"); // Ou ajuste conforme necessário
    setNomeUsuario(usuario || "Usuário");      
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();

    // Carrega a lista de produtos na montagem do componente
    ListarVendas(1, qtd_reg_pagina);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, [ListarVendas, qtd_reg_pagina]);

  return (
    <>
      <VendaCad onClose={RefreshDados} />    
      <Navbar nomeUsuario={nomeUsuario} paginaAtual="Pedidos" /> {/* Adicionando a Navbar */}      
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="pedidos" />
          </div>
          <div className="col py-3 me-3">
            <div className="mb-5">
              <Busca
                criterioBusca={criterioBusca}
                handleCriterioChange={handleCriterioChange}
                termoBusca={termoBusca}
                handleBuscaChange={handleBuscaChange}
                onBuscar={handleBuscar}
                texto="Digite o termo de busca"
            />
            </div>
            <div className="bg-white p-4 rounded-4 border">
              <div className="d-flex justify-content-between mb-3">
                <h2>Pedidos</h2>
                    <button onClick={Novo} className="btn btn-primary ms-4">
                      <i className="bi bi-plus-lg me-2"></i>Novo Pedido
                    </button>                              
              </div>
              {isMobile ? (
                <div className="card-container">
                  {dados.map((item) => (
                    <div className="card" key={item.id}>
                      <div className="card-header">{item.idpedido}</div>
                        <div className="card-body">
                          <p><strong>Código:</strong> {item.idpedido}</p>
                          <p><strong>Data Emissão:</strong> {item.dta_pedido}</p>
                          <p><strong>Cliente:</strong> {item.descricao}</p>
                          <p><strong>Entregador:</strong> {item.nome_entregador}</p>
                          <p><strong>Entregador:</strong> {item.nome_maquininha}</p>
                          <p><strong>Situação:</strong> {item.nome_situacao}</p>
                          <p><strong>Vl. pedido:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}</p>
                        </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-table-container">
                  <DataTable
                    columns={columns}
                    data={dados}
                    pagination={true}
                    paginationComponentOptions={paginationOptions}
                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                    paginationServer={true}
                    paginationTotalRows={total_registros}
                    onChangeRowsPerPage={ChangePerRows}
                    onChangePage={ChangePage}
                    progressPending={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pedidos;
