import { useEffect, useState } from "react";
import { Dock } from "react-dock";
import "./venda-cad.css";
import api from "../../services/api.js";

function VendaCad(props){

    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [entregadores, setEntregadores] = useState([]);
    const [idUsuario, setIdUsuario] = useState(0);

    // Função para obter o ID do usuário logado
    const getLoggedUserId = () => {
        // Exemplo de como pegar o ID do usuário logado (pode vir do localStorage, API, ou contexto)
        const user = localStorage.getItem('idusuario');  // Suponha que o ID do usuário esteja salvo no localStorage
        return user ? parseInt(user) : 0;  // Se for um número, converte para inteiro; caso contrário, retorna 0
    };
    
    // Estado único para o formulário
    const [formData, setFormData] = useState({
        idUsuario: getLoggedUserId(),  // Inicializa com o ID do usuário
        codigo: "",
        etapa: "",
        descricao: "",
        codbarra: "",
        referencia: "",
        dataPedido: "",
        entregador: ""
    });

    // Função para manipular a mudança de valores do formulário
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Função para buscar entregadores
    const CarregarEntregadores = () => {
        api.get("/entregadores/listagem")
        .then((resp) => {
            const data = resp.data;
            // Se data for um objeto, transformá-lo em um array
            if (Array.isArray(data)) {            
                setEntregadores(data);
            } else {
                setEntregadores([data]);
            }
        })
        .catch(() => {
            setEntregadores([]); // Definir array vazio caso dê erro
            alert("Erro ao buscar entregadores");
        });
    };
    // Função para salvar os dados
    const SalvarDados = () => {
        const params = { ...formData, idUsuario };
    
        // Verificação básica de dados
        if (!params.codigo || !params.descricao || !params.referencia) {
            alert("Preencha todos os campos obrigatórios.");
            return;
        }
    
        // Operação de inclusão de novo registro
        if (operacao === "new") {
            api.post("/pedidos", params)
            .then((resp) => {
                if (resp.status === 201) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                //console.error("Erro ao inserir dados: ", err);  // Logando o erro
                alert("Erro ao inserir dados");
            });
        }
    };

    // Carregar entregadores e definir operação quando a sidebar for aberta
    useEffect(() => {        
        CarregarEntregadores();
        // Obter o ID do usuário logado e atualizar o estado
        const userId = getLoggedUserId();
        setIdUsuario(userId);  // Atualiza o estado com o ID do usuário logado

        setFormData((prevFormData) => ({
            ...prevFormData,
            idUsuario: userId
        }));
        
        const handleOpenSidebar = (param) => {
            if (param.detail.operacao === "new") {
                // Limpar os campos para um novo registro
                setFormData({
                    idUsuario: userId,
                    id_negocio: 0,
                    codigo: "",
                    descricao: "",
                    referencia: "",
                    dataPedido: "",
                    entregador: ""
                });
                setOperacao(param.detail.operacao);
            }
            setShow(true);
        };

        // Adicionar evento para abrir a sidebar
        window.addEventListener('openSidebar', handleOpenSidebar);

        // Remover evento ao desmontar componente
        return () => {
            window.removeEventListener('openSidebar', handleOpenSidebar);
        };
    }, []);

    return (
        <Dock 
            position="right"
            isVisible={show}
            fluid={false}
            size={410}   
            onVisibleChange={(visible) => setShow(visible)}
        >
            <div className="container-fluid h-100 pt-4 sidebar p-4">
                <h4 className="d-inline">Novo Pedido</h4>

                <div className="row tela-cad">
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Código</small>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="id_negocio"
                            value={formData.id_negocio} 
                            onChange={handleInputChange} 
                            disabled 
                        />
                    </div>
                
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Nº do Pedido</small>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="codigo" 
                            value={formData.codigo} 
                            onChange={handleInputChange} 
                        />
                    </div>
                    
                    {/* Campo Descrição do Pedido */}
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Cliente</small>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="descricao" 
                            value={formData.descricao} 
                            onChange={handleInputChange} 
                        />
                    </div>
                    
                    {/* Campo de Data do Pedido */}
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Data do Pedido</small>
                        <input 
                            type="date" 
                            className="form-control" 
                            name="dataPedido" 
                            value={formData.dataPedido} 
                            onChange={handleInputChange} 
                        />
                    </div>
                
                    {/* Combo para Entregador */}
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Entregador</small>
                        <select 
                            className="form-control" 
                            name="entregador" 
                            value={formData.entregador} 
                            onChange={handleInputChange}
                        >
                            <option value="">Selecione o Entregador</option>
                            {
                                Array.isArray(entregadores) && entregadores.map((item) => (
                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                ))
                            }
                        </select>
                    </div>
                
                    <div className="col-12 mt-3">
                        <small className="text-secondary">Valor</small>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="referencia" 
                            value={formData.referencia} 
                            onChange={handleInputChange} 
                        />
                    </div>
                </div>

                <div className="row align-items-end footer">
                    <div className="text-center">
                        <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Cancelar</button>
                        <button className="btn btn-primary" onClick={SalvarDados}>Salvar Dados</button>
                    </div> 
                </div>
            </div>
        </Dock>
    );
}

export default VendaCad;