import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";

function Menu(props){

    const linkBlue = "nav-link align-middle px-0"
    const linkBlack = "nav-link align-middle px-0 link-secondary"

    const navigate = useNavigate();

    function Logout(){
        localStorage.removeItem("idusuario");
        navigate("/login");
    }

    return <>
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    
                    <span className="fs-5 d-none d-sm-inline">
                        <img src={logo} className="img-logo" width="180" heigth="230"/>
                    </span>
                </a>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                    <li className="nav-item">
                        <Link to="/dashboard" className={props.page === "dashboard" ? linkBlue : linkBlack}>
                            <i className="fs-4 bi bi-bar-chart"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/entregador" className={props.page == "entregador" ? linkBlue : linkBlack}>
                            <i class="bi bi-people-fill"></i> <span className="ms-1 d-none d-sm-inline">Entregadores</span>
                        </Link>
                    </li>
                    
                    <li className="nav-item">
                        <Link to="/maquininhas" className={props.page == "maquininhas" ? linkBlue : linkBlack}>
                            <i className="fs-4 bi-wallet2"></i> <span className="ms-1 d-none d-sm-inline">Maquininhas</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/pedidos" className={props.page == "pedidos" ? linkBlue : linkBlack}>
                            <i class="bi bi-cash-coin"></i> <span className="ms-1 d-none d-sm-inline">Pedidos</span>
                        </Link>
                    </li>
                    
                    <li className="nav-item">
                        <a onClick={Logout} href="#" className={props.page == "logout" ? linkBlue : linkBlack}>
                            <i className="fs-4 bi-box-arrow-left"></i> <span className="ms-1 d-none d-sm-inline">Desconectar</span>
                        </a>
                    </li>
                    
                    
                </ul>
                
            </div>  
    </>
}

export default Menu;