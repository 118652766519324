import React, { useEffect, useState, useCallback, useMemo } from "react";
import Busca from "../../components/busca/busca.jsx";
import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import AjustaEstoqueCad from "../ajustarestoque-cad/ajustarestoque-cad.jsx";
import Navbar from "../../components/navbar/navbar.jsx"; // Importando o componente Navbar
import api from "../../services/api.js";

function Maquininhas() {
  const [termoBusca, setTermoBusca] = useState(""); // Termo da busca
  const [criterioBusca, setCriterioBusca] = useState("operadora"); // Critério da busca  
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [isMobile, setIsMobile] = useState(false);
  const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
  const [dados, setDados] = useState([]);
  const [total_registros, setTotalRegistros] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [nomeUsuario, setNomeUsuario] = useState("Usuário"); // Estado para o nome do usuário
  
  const columns = useMemo(() => [
    { name: 'Código', selector: row => row.id, sortable: true, width: "120px" },
    { name: 'Operadora', selector: row => row.operadora, sortable: true, compact: true },
    { name: 'NSerial', selector: row => row.nsserial, sortable: true },
    { name: 'Serial', selector: row => row.serial, sortable: true },
    {
      cell: (row) => (
        <>
          <button onClick={() => Editar(row.id)} className="btn btn-primary ms-3"><i className="bi bi-pencil-square"></i></button>
          <button onClick={() => Excluir(row.id)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button>
        </>
      ),
      width: "200px",
      right: true
    }    
  ], []);

  const paginationOptions = {        
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos'        
  };

  const ListarProdutos = useCallback(async (pagina = 1, qtd_reg_pagina = 10, termoBusca = "", criterioBusca = "operadora") => {
    setLoading(true);
    try {
      const resp = await api.get("/maquininhas", { params: { pagina, qtd_reg_pagina, busca: termoBusca, criterio: criterioBusca } });
      setTotalRegistros(resp.data.total_registros);
      setDados(resp.data.dados);
    } catch (err) {
      setTotalRegistros(0);
      setDados([]);
      alert("Erro ao carregar Maquininhas");
    } finally {
      setLoading(false);
    }
  }, []);

  const Excluir = useCallback((id) => {
    confirmAlert({
      title: 'Exclusão',
      message: 'Confirma exclusão do Produto?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              await api.delete("/maquininhas/" + id);
              ListarProdutos(currentPage, qtd_reg_pagina);
            } catch (err) {
              alert("Erro ao excluir o Produto");
            }
          }
        },
        { label: 'Não' }
      ]
    });
  }, [currentPage, qtd_reg_pagina, ListarProdutos]);

  const Editar = (id) => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "edit", codigo: id } });
    window.dispatchEvent(event);
  };

  const Novo = () => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "new" } });
    window.dispatchEvent(event);
  };

  const ChangePerRows = (newQtd, page, termoBusca, criterioBusca) => {
    setQtdRegPagina(newQtd);
    setCurrentPage(page);
    ListarProdutos(page, newQtd, termoBusca, criterioBusca);
  };

  const ChangePage = (page) => {
    setCurrentPage(page);
    ListarProdutos(page, qtd_reg_pagina, termoBusca, criterioBusca);
  };

  const RefreshDados = () => ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca);
    function handleBuscaChange(e) {
        setTermoBusca(e.target.value); // Atualiza o termo de busca
    }

    function handleCriterioChange(e) {
        setCriterioBusca(e.target.value); // Atualiza o critério de busca
    }

    function handleBuscar() {
        ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca); // Executa a busca com termo e critério
    }
    
  // Função para detectar ambiente (desktop ou mobile)
  useEffect(() => {
    // Supondo que o nome do usuário seja armazenado no localStorage
    const usuario = localStorage.getItem("nome_usuario"); // Ou ajuste conforme necessário
    setNomeUsuario(usuario || "Usuário");      
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();

    // Carrega a lista de produtos na montagem do componente
    ListarProdutos(1, qtd_reg_pagina);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, [ListarProdutos, qtd_reg_pagina]);

  return (
    <>
    <AjustaEstoqueCad onClose={RefreshDados} />
      <Navbar nomeUsuario={nomeUsuario} paginaAtual="Maquininhas" /> {/* Adicionando a Navbar */}
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="maquininhas" />
          </div>
          <div className="col py-3 me-3">
            <div className="mb-5">
              <Busca
                criterioBusca={criterioBusca}
                handleCriterioChange={handleCriterioChange}
                termoBusca={termoBusca}
                handleBuscaChange={handleBuscaChange}
                onBuscar={handleBuscar}
                texto="Digite o termo de busca"
            />
            </div>
            <div className="bg-white p-4 rounded-4 border">
              <div className="d-flex justify-content-between mb-3">
                <h2>Maquininha</h2>
                {isMobile ? (
                  <button onClick={Novo} className="btn btn-primary ms-auto">
                    <i className="bi bi-plus-lg me-2"></i>
                  </button>
                ) : (
                  <button onClick={Novo} className="btn btn-primary ms-4">
                    <i className="bi bi-plus-lg me-2"></i>Nova Maquininha
                  </button>
                )}
              </div>
              {isMobile ? (
                <div className="card-container">
                  {dados.map((item) => (
                    <div className="card" key={item.id}>
                      <div className="card-header">{item.operadora}</div>
                      <div className="card-body">
                        <p><strong>Código:</strong> {item.id}</p>
                        <p><strong>Operadora:</strong> {item.operadora}</p>
                        <p><strong>NSerial:</strong> {item.nsserial}</p>
                        <p><strong>Serial:</strong> {item.serial}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-table-container">
                  <DataTable
                    columns={columns}
                    data={dados}
                    pagination={true}
                    paginationComponentOptions={paginationOptions}
                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                    paginationServer={true}
                    paginationTotalRows={total_registros}
                    onChangeRowsPerPage={ChangePerRows}
                    onChangePage={ChangePage}
                    progressPending={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Maquininhas;
